import React from "react";

const Footer = () => {
  return (
    <>
      <section className="footer_sec">
        <div className="footer_two_imgs">
          <div className="footer_left_img">
            <img src="./images/bad-ass-v2.png" alt="footer_left" />
          </div>
          <div className="footer_right_img">
            <div className="right_img_inner">
              <img src="./images/CA.png" alt="footer_right" />
            </div>
            <div className="right_text_inner">
              <a
                href="https://basescan.org/address/0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380#code"
                target="_blank"
              >
                <div className="text_right_footer">
                  0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer_text">
                DISCLOSURE By purchasing $ASS coin, you agree that you are not
                purchasing a security or investment contract and you agree to
                hold the team harmless and not liable for any losses or taxes
                you may incur. Although BAD ASS is a community-driven DeFi
                Ecosystem and not a registered digital currency, always make
                sure that you are in compliance with local laws and regulations
                before you make any purchase.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
