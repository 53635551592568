import React from "react";

const Hold = () => {
  return (
    <>
      <section className="hold_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 mx-auto">
              <div className="hold_img">
                <img src="./images/donkey-meme.png" alt="hold_img" />
              </div>
              <div className="hold_headings">
                <div className="hold_top_heading">HODL my</div>
                <div className="hold_bottom_heading">$ASS</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hold;
