import React from "react";

const About = () => {
  return (
    <>
      <section className="tokenomics_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 mx-auto" id="Tokenomics">
              {/* <div className='row'>
            <div className='col-5'>
            <div className='tockenomic'>
              <img src='./images/tokenomics-sction.png' alt='tockenomic' />
          </div>
            </div>
            <div className='col-7'>

            </div>

        </div> */}

              {/* <div className='tockenomic'>
              <img src='./images/tokenomics-sction.png' alt='tockenomic' />
          </div> */}
              <div className="tokenomics_main_heading">
                <img src="./images/tokenomics_heading.png" alt="tockenomic" />
              </div>
              <div className="tokenomics_body">
                <div className="tokenomic_flex">
                  <div className="img_part">
                    <img src="./images/supply.png" alt="tockenomic" />
                  </div>
                  <div className="text_part text1">
                    420,690,420,690.42069042069042069
                  </div>
                </div>
                <div className="tokenomic_flex">
                  <div className="img_part">
                    <img src="./images/tax.png" alt="tockenomic" />
                  </div>
                  <div className="text_part text2">0%</div>
                </div>
                <div className="tokenomic_flex">
                  <div className="img_part lp_img">
                    <img src="./images/lp.png" alt="tockenomic" />
                  </div>
                  <a
                    href="https://basescan.org/tx/0x83debd4a30fc327d8e694d95a8f27d0ead578783ff372cfb7ea7b84f855e6a25"
                    target="_blank"
                  >
                    <div className="text_part text3">Burned ()</div>
                  </a>
                </div>
                <div className="tokenomic_flex">
                  <div className="img_part">
                    <img src="./images/liquid.png" alt="tockenomic" />
                  </div>
                  <a
                    href="https://basescan.org/tx/0x868be249e04e28fedd7c5e515444192f84c63cdefcfaaae60d6c2ee88b2cffcb"
                    target="_blank"
                  >
                    <div className="text_part text4">Locked ()</div>
                  </a>
                </div>
                <div className="tokenomic_flex">
                  <div className="img_part">
                    <img src="./images/token_ca.png" alt="tockenomic" />
                  </div>
                  <a
                    href="https://basescan.org/address/0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380#code"
                    target="_blank"
                  >
                    <div className="text_part text5">
                      0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
