import React from "react";

const About = () => {
    return (
        <>
            <section className="about_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-12 mx-auto">
                            <div className="row">
                                <div className="col-6">
                                    <div className="about_left_img">
                                        <img src="./images/about.png" alt="left" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="about_right_img">
                                        <img src="./images/about_right.png" alt="left" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10 col-12 mx-auto">
                            <div className="about_headings">
                                <div className="about_heading_top">it’s just a</div>
                                <div className="about_heading_center">BAD A$$</div>
                                <div className="about_heading_bottom">COIN</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
