import React from "react";

const About = () => {
    return (
        <>
            <section className="chart_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-12 mx-auto">
                            <div className="chart_main">
                                <img src="./images/chart.png" alt="chart" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
