import React from "react";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-md bg-dark navbar-dark main_nav">
        <div className="container-fluid main_container py-2">
          <a
            className="navbar-brand"
            href="https://dexscreener.com/base/0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380"
          >
            {" "}
            <img src="./images/ASS-LOGO.png" alt="logo" />
            <span className="logo_text">BAD A$$</span>{" "}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav all_links">
              <li className="nav-item">
                <a className="nav-link" href="#Tokenomics">
                  Tokenomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#Community">
                  Community
                </a>
              </li>
            </ul>
          </div>
          <div>
            <div className="right_btn">
              <a href="https://t.me/badasscoin" target="_blank">
                <button className="tele">Telegram</button>
              </a>
              <a
                href="https://dexscreener.com/base/0xac65d024aaf1f173d2fa28c4a05a8ea0f8f6b380"
                target="_blank"
              >
                <button className="buy">Buy $ASS</button>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
