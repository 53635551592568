import React from "react";

const Header = () => {
    return (
        <>
            <section className="header_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-12 mx-auto">
                            <div className="header_main_text">
                                <div className="header_heading1">BAD A$$</div>
                                <div className="header_heading2">COIN</div>
                                <div className="header_img">
                                    <img src="./images/Ass-coin-circular.png" alt="header" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;
