import React from "react";

const Comunity = () => {
    return (
        <>
            <section className="about_sec" id="Community">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-12 mx-auto">
                            <div className="row">
                                <div className="col-lg-7 col-12">
                                    <div className="comunity_left_main">
                                        <div className="couminity_inner">
                                            <img src="./images/comunity-left.png" alt="left" />
                                        </div>
                                        {/* <div className='couminity_outer'>
            <img src='./images/about.png' alt='left' />
            </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-12">
                                    <div className="comunity_right">
                                        <div className="comunity_top_img text-center">
                                            <img src="./images/community.png" alt="comunity|_top" />
                                        </div>
                                        <div className="social_icons">
                                            <div className="tele_social">
                                                <a href="https://t.me/badasscoin">
                                                    <img src="./images/telegram.png" alt="comunity|_top" />
                                                </a>
                                            </div>
                                            <div className="tele_social">
                                                <a href="https://twitter.com/RealBadAssCoin">
                                                    <img src="./images/twitter.png" alt="comunity|_top" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Comunity;
